<template>
  <section v-if="campaignData">

    <!-- header row -->
    <b-row class="content-header">
      <!-- don't show header if sidebar view -->
      <b-col
        v-if="!campaignId"
        cols="12"
      >
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{ name: 'browse-campaigns' }"
            >
              {{ resourceNamePlural }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'view-campaign', params: { id: resourceId } }">
              Campaign Details
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>

      <!-- edit button -->
      <b-col
        md="12"
        class="mb-2 text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'edit-campaign', params: { id: resourceId } }"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-text-top"
          />  Edit {{ resourceName }}
        </b-button>
      </b-col>
    </b-row>

    <!-- resource details -->
    <b-row>
      <b-col>
        <b-card>
          <b-card-title class="pl-1 border-left-primary border-left-3">
            {{ resourceName }} Details
          </b-card-title>
          <!-- <div class="access-items">
            <div class="mb-2">
              <div class="pt-1 mr-1 float-left">
                <h5>Access:</h5>
              </div>
              <b-avatar-group size="32px">
                <b-avatar
                  class="pull-up"
                  :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')"
                />
                <b-avatar
                  class="pull-up"
                  variant="primary"
                  :src="require('@/assets/images/portrait/small/avatar-s-7.jpg')"
                />
                <b-avatar
                  class="pull-up"
                  :src="require('@/assets/images/portrait/small/avatar-s-10.jpg')"
                />
                <b-avatar
                  class="pull-up"
                  variant="danger"
                  :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
                />
                <b-avatar
                  class="pull-up"
                  :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
                />
              </b-avatar-group>
            </div>
          </div> -->
          <div class="detail-items">
            <!-- name -->
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                Name
              </h5>
              <p class="m-0">
                {{ campaignData.name }}
              </p>
            </div>

            <!-- start date -->
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                Start Date
              </h5>
              <p class="m-0">
                {{ getReadableDate(campaignData.start_date) }}
              </p>
            </div>

            <!-- end date -->
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                End Date
              </h5>
              <p class="m-0">
                {{ getReadableDate(campaignData.end_date) }}
              </p>
            </div>

            <!-- carrier design -->
            <div class="p-1 border-bottom rounded">
              <h5 class="text-capitalize">
                Carrier Design
              </h5>
              <p class="m-0">
                <b-button
                  v-if="campaignData.design_images_printable && campaignData.design_images_printable.length > 0"
                  v-b-modal.modal-view-printable
                  variant="primary"
                  class="mr-1"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                    class="align-text-top"
                  /> View Designs
                </b-button>

                <b-button
                  variant="outline-primary"
                  :to="{ name: 'create-design', params: { id: resourceId } }"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-text-top"
                  /> Create Designs
                </b-button>

                <b-button
                  v-if="campaignData.design_images_printable && campaignData.design_images_printable.length > 0"
                  variant="success"
                  class="ml-1"
                  @click="openPrintersModal"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    size="16"
                    class="align-text-top"
                  /> Submit to Printer
                </b-button>
              </p>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- other details -->
    <b-row>
      <b-col>
        <b-card class="mt-2">
          <b-card-title class="pl-1 border-left-primary border-left-3">
            {{ resourceName }} Events
          </b-card-title>
          <b-row>
            <b-col md="12">
              <div class="text-right">
                <b-button
                  variant="outline-primary"
                  class="mr-1"
                  :to="{ name: 'event-calendar', params: { id: resourceId } }"
                >
                  <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    class="align-text-top"
                  /> Calendar View
                </b-button>
                <b-button
                  variant="primary"
                  @click="openEventCreateModal"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="16"
                    class="align-text-top"
                  /> Add New
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row v-if="campaignEventData && campaignEventData.length <= 0">
            <b-col>
              No events to show.
            </b-col>
          </b-row>
          <b-row v-if="campaignEventData && campaignEventData.length > 0">
            <b-col cols="12 mt-2">
              <b-table
                hover
                responsive
                :items="campaignEventData"
                :fields="fields"
              >

                <template #cell(name)="data">
                  <!-- destination url link -->
                  <b-link
                    :href="data.item.destination_url"
                    target="_blank"
                  >
                    {{ data.item.name }}
                    <b-badge
                      pill
                      variant="light-primary"
                    >
                      <feather-icon icon="ExternalLinkIcon" />
                    </b-badge>
                  </b-link>
                </template>

                <template #cell(default)="data">
                  <b-badge
                    v-if="data.item.default"
                    variant="primary"
                  >
                    Default
                  </b-badge>
                  <b-badge
                    v-if="!data.item.default"
                    variant="light-secondary"
                    @click="setEventDefault(data.item)"
                  >
                    Set as default
                  </b-badge>
                </template>

                <template #cell(actions)="data">
                  <!-- edit event -->
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    target="_blank"
                    @click="openEventEditModal(data.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="14"
                      class="align-text-top"
                    />
                    Edit
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <!-- <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- create event modal -->
    <b-modal
      id="modal-create-event"
      modal-class="modal-primary"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
      :title="`${campaignEventAction} Campaign Event`"
    >
      <b-card-text>
        <validation-observer
          ref="createEventForm"
          #default="{invalid, dirty}"
        >
          <b-form @submit.prevent="campaignEventSubmit">
            <b-row>
              <!-- name -->
              <b-col cols="12">
                <b-form-group
                  label="Campaign Event Name"
                  label-for="campaign-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Campaign Event Name"
                    rules="required"
                    vid="campaign-name"
                  >
                    <b-form-input
                      id="campaign-name"
                      v-model="campaignEventForm.name"
                      name="campaign-name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Campaign Event Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- start date -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="Start Date"
                  rules="required"
                  vid="start_datetime"
                >
                  <DateTimeForm
                    id="start_datetime"
                    v-model="campaignEventForm.start_datetime"
                    prefix="Start"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- end date -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="End Date"
                  rules="required"
                  vid="end_datetime"
                >
                  <DateTimeForm
                    id="end_datetime"
                    v-model="campaignEventForm.end_datetime"
                    prefix="End"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <!-- destination url -->
              <b-col cols="12">
                <b-form-group
                  label="Destination URL"
                  label-for="destination_url"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Destination URL"
                    rules="required"
                    vid="destination_url"
                  >
                    <b-form-input
                      id="destination_url"
                      v-model="campaignEventForm.destination_url"
                      name="destination_url"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Destination URL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr>
              </b-col>

              <!-- submit -->
              <b-col cols="6">
                <b-button
                  v-if="campaignEventAction === 'Edit'"
                  variant="outline-danger"
                  class="mr-1"
                  @click="deleteEvent(campaignEventForm)"
                >
                  Delete
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="text-right"
              >
                <b-button
                  variant="outline-secondary"
                  class="mr-1"
                  @click="closeEventModal"
                >
                  Cancel
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="invalid || !dirty"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

    <!-- view printable design modal -->
    <b-modal
      id="modal-view-printable"
      modal-class="modal-primary"
      size="xl"
      ok-only
      centered
      scrollable
      title="Preview Printable Designs"
    >
      <div
        v-if="campaignData"
        class="text-center preview-container"
      >
        <b-link
          v-if="campaignData.design_images_printable && campaignData.design_images_printable[0]"
          :href="campaignData.design_images_printable[0].url"
          target="_blank"
        >
          <b-img
            class="p-2 preview-image"
            style="max-height: 80vh"
            :src="campaignData.design_images_printable[0].url"
          />
        </b-link>
        <b-link
          v-if="campaignData.design_images_printable && campaignData.design_images_printable[1]"
          :href="campaignData.design_images_printable[1].url"
          target="_blank"
        >
          <b-img
            class="p-2 preview-image"
            style="max-height: 80vh"
            :src="campaignData.design_images_printable[1].url"
          />
        </b-link>
      </div>
    </b-modal>

    <!-- submit printer modal -->
    <b-modal
      id="modal-submit-printer"
      modal-class="modal-primary"
      size="lg"
      centered
      hide-footer
      no-close-on-backdrop
      title="Submit to Printer"
    >
      <b-card-text>
        <validation-observer
          ref="submitPrinterForm"
          #default="{invalid, dirty}"
        >
          <b-form @submit.prevent="printerFormSubmit">
            <b-row>
              <!-- select printer -->
              <b-col cols="12">
                <b-form-group
                  label="Select Printer"
                  label-for="printer_id"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Select Printer"
                    rules="required"
                    vid="printer_id"
                  >
                    <b-form-select
                      id="printer_id"
                      v-model="printersForm.printer_id"
                      :options="printersList"
                      value-field="id"
                      text-field="full_name"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- note to printer -->
              <b-col cols="12">
                <b-form-group
                  label="Note to Printer"
                  label-for="printer_note"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Note to Printer"
                    rules="required"
                    vid="printer_note"
                  >
                    <b-form-textarea
                      id="printer_note"
                      v-model="printersForm.printer_note"
                      name="printer_note"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Note to Printer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <hr>
              </b-col>

              <!-- submit -->
              <b-col
                cols="12"
                class="text-right"
              >
                <b-button
                  variant="outline-secondary"
                  class="mr-1"
                  @click="closePrintersModal"
                >
                  Cancel
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="invalid || !dirty"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>

  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard, BCardTitle, BCardText, BBadge, BButton, BLink, BImg,
  // BAvatarGroup, BAvatar,
  BTable, BForm, BFormGroup, BFormInput, BFormSelect, BFormTextarea,
  // BPagination, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import DateTimeForm from '@/views/components/date-time-form/DateTimeForm.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BBadge,
    BButton,
    BLink,
    BImg,
    // BAvatarGroup,
    // BAvatar,
    BTable,
    BForm,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    // BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,

    // validations
    ValidationProvider,
    ValidationObserver,

    // date time form
    DateTimeForm,
  },
  props: {
    campaignId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      resourceName: 'Campaign',
      resourceNamePlural: 'Campaigns',
      resource: {},
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user
      userData: JSON.parse(localStorage.getItem('userData')),

      // printers
      printersList: [],
      printersForm: {
        id: this.resourceId, // campaign id
        printer_id: null,
        printer_note: null,
      },

      // events form
      campaignEventAction: 'Create',
      campaignEventSubmit: this.createCampaignEvent,
      campaignEventForm: {
        name: null,
        start_datetime: undefined,
        end_datetime: undefined,
        destination_url: null,
        default: false,
      },

      // events table
      totalRows: 1,
      fields: [
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'start_datetime', label: 'Start Date', sortable: true },
        { key: 'end_datetime', label: 'End Date', sortable: true },
        { key: 'default', label: 'Default' },
        { key: 'actions', label: 'Actions' },
      ],

      // form validation
      required,
      email,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id || this.campaignId
    },
    campaignData() {
      return this.$store.getters['campaigns/getCampaign']
    },
    campaignEventData() {
      return this.$store.getters['campaigns/getCampaignEvents']
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      this.adminAccess = true
      this.adminSuffix = 'AsRoot'
    }

    // fetch campaign
    const campaignId = this.resourceId
    this.$store.dispatch(`campaigns/fetchCampaign${this.adminSuffix}`, campaignId)
      .then(() => {
        // fetch campaign events
        this.$store.dispatch(`campaigns/fetchCampaignEvents${this.adminSuffix}`, campaignId)
      })
      .catch(error => {
        console.log('error', error.response)
      })
  },
  methods: {
    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
    openPrintersModal() {
      useJwt[`getPrinters${this.adminSuffix}`]()
        .then(response => {
          const responseData = response.data.data
          this.printersList = responseData
        })
        .catch(error => {
          console.log(error.response.data.error)
        })

      // this.resetPrinterForm()
      this.$bvModal.show('modal-submit-printer')
    },
    closePrintersModal() {
      this.$bvModal.hide('modal-submit-printer')
      // this.resetPrinterForm()
    },
    printerFormSubmit() {
      console.log('printerFormSubmit')
      this.$refs.submitPrinterForm.validate().then(success => {
        if (success) {
          // reset form validation state so we can track if form data is dirty
          this.$refs.submitPrinterForm.reset()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Submitted to Printer',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Campaign submitted to printer.',
            },
          })
          this.closePrintersModal()
        }
      })
    },
    resetEventForm() {
      this.campaignEventForm = {
        name: null,
        start_datetime: undefined,
        end_datetime: undefined,
        destination_url: null,
        default: false,
      }
    },
    openEventCreateModal() {
      this.resetEventForm()
      this.campaignEventAction = 'Create'
      this.campaignEventSubmit = this.createCampaignEvent
      this.$bvModal.show('modal-create-event')
    },
    openEventEditModal(eventData) {
      this.resetEventForm()
      this.campaignEventAction = 'Edit'
      this.campaignEventSubmit = this.editCampaignEvent

      // copy eventData for form
      this.campaignEventForm = { ...eventData }

      this.$bvModal.show('modal-create-event')
    },
    closeEventModal() {
      this.$bvModal.hide('modal-create-event')
      this.resetEventForm()
    },
    refreshCampaignEvents() {
      // fetch campaign events
      this.$store.dispatch(`campaigns/fetchCampaignEvents${this.adminSuffix}`, this.resourceId)

      /* useJwt.getCampaignEvents(this.resourceId)
        .then(response => {
          const campaignEvents = response.data.data
          this.campaignEvents = campaignEvents
          // Set the initial number of items
          this.totalRows = campaignEvents.length
        })
        .catch(error => {
          console.log(error)
        }) */
    },
    createCampaignEvent() {
      console.log('createCampaignEvent')
      this.$refs.createEventForm.validate().then(success => {
        if (success) {
          // reset form validation state so we can track if form data is dirty
          this.$refs.createEventForm.reset()
          useJwt[`createCampaignEvent${this.adminSuffix}`](
            this.resourceId,
            this.campaignEventForm,
          )
            .then(response => {
              const resourceData = response.data.data
              this.refreshCampaignEvents()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Created`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Campaign event created successfully.',
                },
              })
              this.closeEventModal()
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.createEventForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    editCampaignEvent() {
      this.$refs.createEventForm.validate().then(success => {
        if (success) {
          // reset form validation state so we can track if form data is dirty
          this.$refs.createEventForm.reset()
          useJwt[`editCampaignEvent${this.adminSuffix}`](
            this.campaignEventForm.id,
            this.campaignEventForm,
          )
            .then(response => {
              const resourceData = response.data.data
              this.refreshCampaignEvents()

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Updated`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Campaign event updated successfully.',
                },
              })
              this.closeEventModal()
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.createEventForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
    deleteEvent(eventData) {
      // confirm alert
      this.$swal({
        title: 'Delete event?',
        text: `"${eventData.name}" will deleted. This cannot be reverted.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt[`deleteCampaignEvent${this.adminSuffix}`](
            eventData.id,
          )
            .then(() => {
              // const responseData = response.data.data
              // console.log('setCampaignEventDefault', responseData)
              this.closeEventModal()
              this.refreshCampaignEvents()

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Your event has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // TODO: alert user to errors
                console.log(error.response.data.error)
              }
            })
        }
      })
    },
    setEventDefault(eventData) {
      // confirm alert
      this.$swal({
        title: 'Set as default?',
        text: `"${eventData.name}" will be the new default event.`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, make it default',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt[`setCampaignEventDefault${this.adminSuffix}`](
            eventData.id,
          )
            .then(() => {
              // const responseData = response.data.data
              // console.log('setCampaignEventDefault', responseData)
              this.refreshCampaignEvents()

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Default campaign event set.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // TODO: alert user to errors
                console.log(error.response.data.error)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

section.sidebar .row {
  margin-left: 0;
  margin-right: 0;
}

.preview-container .preview-image {
  max-height: 80vh;
}
</style>
